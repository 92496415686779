import React from "react";
import {
    WhatsappShareButton,
    TelegramShareButton,
    RedditShareButton,
    TwitterIcon,
    WhatsappIcon,
    TelegramIcon,
    RedditIcon,
} from "react-share";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { IconButton } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import ReactGA from "react-ga4";

export function PositionedSnackbar({ url, size }) {
    const [innerState, setInnerState] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "center",
    });
    const { vertical, horizontal, open } = innerState;

    const handleClick = (newState) => () => {
        setInnerState({ open: true, ...newState });
    };

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(
                url + "?utm_source=share"
            );
            ReactGA.event("Share", { platform: "Copy" });
            handleClick({
                vertical: "top",
                horizontal: "center",
            })();
        } catch (err) {
            //Error!
        }
    };

    const handleClose = () => {
        setInnerState({ open: false });
    };

    const buttons = (
        <React.Fragment>
            <IconButton
                variant="contained"
                style={{
                    backgroundColor: "white",
                    height: `${size}px`,
                    width: `${size}px`,
                }}
                onClick={handleCopyClick}
            >
                <ContentCopyOutlinedIcon style={{ height: "25px" }} />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            {buttons}
            <Snackbar
                style={{textAlign: "center"}}
                anchorOrigin={{
                    vertical: vertical || "top",
                    horizontal: horizontal || "center",
                }}
                open={open}
                onClose={handleClose}
                message="Link Copied"
                key={vertical + horizontal}
                autoHideDuration={1500}
            />
        </div>
    );
}

export default function ShareButtons({
    size = 40,
    successPercentage = NaN,
}) {
    const hashtag = "#HamasisISIS";
    let shareText;
    if (isNaN(successPercentage)) {
        shareText = `Was it ISIS Or Hamas? Do you think you can distinguish between them? test yourself!\n\n${hashtag}\n`;
      } else {
        shareText = `I got ${successPercentage}%, can you do better?\nWas it ISIS Or Hamas? Do you think you can distinguish between them? test yourself!\n\n${hashtag}\n`;
      }
    let url = `https://isisorhamas.com/`;

    return (
        <div
            style={{
                display: "flex",
                padding: "10px 0px",
                justifyContent: "center",
                backgroundColor: "transparent"
            }}
        >
            <PositionedSnackbar url={url} size={size} />
            <TelegramShareButton
                onClick={() => ReactGA.event("TelegramShare")}
                url={url + "?utm_source=telegram"}
                hashtag={hashtag}
                title={shareText}
            >
                <TelegramIcon size={size} round />
            </TelegramShareButton>
            <TwitterIcon
                onClick={() =>
                    window.open(
                        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                            shareText +
                                url +
                                "?utm_source=twitter"
                        )}`
                    ) && ReactGA.event("TwitterShare")
                }
                style={{ height: `${size}px`, width: `${size}px` }}
                round
            />
            <WhatsappShareButton
                onClick={() => ReactGA.event("WhatsappShare")}
                url={url + "?utm_source=whatsapp"}
                hashtag={hashtag}
                title={shareText}
            >
                <WhatsappIcon size={size} round />
            </WhatsappShareButton>
            <RedditShareButton
                onClick={() => ReactGA.event("RedditShare")}
                url={url + "?utm_source=reddit"}
                hashtag={hashtag}
                title={shareText}
            >
                <RedditIcon size={size} round />
            </RedditShareButton>
        </div>
    );
}

export function shuffleArray(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  
  export function divideArrayIntoThree(array) {  
    const size = Math.ceil(array.length / 3); // Calculate size of each chunk 
    
    // Create three slices of the array
    const first = array.slice(0, size);
    const second = array.slice(size, size * 2);
    const third = array.slice(size * 2);
  
    return [first, second, third];
  }
  
import * as React from 'react';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {OpenDialog, DialogContent} from '../resources/contexts';
import Button from '@mui/material/Button';





const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
  const { onClose, selectedValue, open, title, image_url, source_url } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };


  return (
    <Dialog className="dialog" onClose={handleClose} open={open} 
            sx={{backdropFilter: "blur(1px)",
            backgroundColor:'rgba(255,255,255,0.4)'}}
               >
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px'}}>
      <div className='dialog_image_div'>
        <img className='dialog_image' src={image_url} alt=""></img>
        <a 
  href={source_url} 
  target="_blank" 
  rel="noopener noreferrer" 
  style={{ 
    position: 'absolute', 
    bottom: '0', 
    left: '2%', 
    margin: '5px 0', 
    backgroundColor: 'white', 
    padding: '0 5px',
    fontSize: '15px', 
    fontWeight: 'bold', 
    textDecoration: 'underline',
  }}
>
  Link
</a>

      </div>

      <Button 
        variant="contained"
        color="primary"
        onClick={handleClose}
        sx={{ alignSelf: 'flex-end',
              marginTop: '10px', 
              fontSize: '0.8rem',
              padding: '3px 12px',
              backgroundColor: '#811f1b',
              color: 'white',
            }}
      >
        Continue
      </Button>
    </div>

    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo() {
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const { openDialog, setOpenDialog } = useContext(OpenDialog);
  const dialogDict = useContext(DialogContent);  
  // Object.entries(dialogDict).forEach(([key, value]) => {
  //   console.log(`Key: ${key}, Value: ${value}`);
  // });

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <SimpleDialog
        selectedValue={selectedValue}
        open={openDialog}
        onClose={handleClose}
        title={dialogDict.title}
        image_url={dialogDict.image_url}
        source_url={dialogDict.source_url}
      />

    </div>
    
    

    

  );
}
import React, { useState, useMemo, useRef, useEffect } from 'react'
import TinderCard from 'react-tinder-card'
import mixpanel from 'mixpanel-browser';
import SimpleDialogDemo from "../components/dialog"
import {OpenDialog, DialogContent} from '../resources/contexts';
import isis_or_hamas_white from '../resources/ISIS_or_Hamas_White.png';
import isis_flag from '../resources/ISIS_flag.png';
import replay_button from '../resources/replay.png'
import hamas_flag from '../resources/Hamas_flag.png';
import youre_right from '../resources/youre_right.png';
import youre_wrong from '../resources/youre_wrong.png';
import both from '../resources/both.png';

import punchline from '../resources/punchline.png';
import precentege_title from '../resources/precentege_title.png';
import ShareButtons from '../components/shareButtons'

import db from '../resources/database.json'
import {shuffleArray, divideArrayIntoThree} from '../resources/utils'

mixpanel.init("b5a8f52afd66ddedbc819d0814004af1", { track_pageview: true, persistence: 'localStorage' });

function Advanced () {
  const [currentIndex, setCurrentIndex] = useState(db.length - 1)
  const [reserveDatabases, setReserveDatabases] = useState([]);
  const [preparedDatabase, setPreparedDatabase] = useState(db);
  const [progressBar, setProgressBar] = useState({});
  const [dialogInfo, setDialogInfo] = useState({});
  const currentIndexRef = useRef(currentIndex)
  const [openDialog, setOpenDialog] = useState(false);
  const initialResultObject = {score: 0, questions: 0};
  const [result, setResult] = useState(initialResultObject);

  // Prepare database
  useEffect(() => {
    if (db) {
      let newData = db.map(group => {
        // If there are two items, choose one at random
        if (group.length > 1) {
          return group[Math.floor(Math.random() * group.length)];
        }
        return group[0]; // If there's only one item, use it
      });
      newData = shuffleArray(newData);
      newData = divideArrayIntoThree(newData);
      setReserveDatabases(newData.slice(1));
      let currentDatabase = newData[0]
      setPreparedDatabase(currentDatabase);
      updateCurrentIndex(currentDatabase.length - 1)
      setProgressBar({currentIndex: 1, denominator: currentDatabase.length})
    }
  }, []);

  const childRefs = useMemo(
    () =>
      Array(db.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  )

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  const canSwipe = currentIndex >= 0



  useEffect(() => {
    // Send mixpanel events with results
    mixpanel.track("Results", {
      "scorePercentage": Math.round(result.score / result.questions * 100),
      "questions": result.questions
    });
  }, [result]);

  const updateResult = (isCorrect) => {
    setResult(prev => {
      return {
        score: isCorrect ? prev.score + 1 : prev.score,
        questions: prev.questions + 1
      }
    })
  };

  const updateCurrentProgressBar = () => {
    setProgressBar(prev => {
      return {
        currentIndex: prev.currentIndex + 1,
        denominator: prev.denominator
      }
    })
  }
  const updateProgressBarDenominator = (numToAdd) => {
    setProgressBar(prev => {
      return {
        currentIndex: prev.currentIndex,
        denominator: prev.denominator + numToAdd
      }
    })  }

  // set last direction and decrease current index
  const swiped = (direction, dialogImageLink, index, correctAnswer, sourceLink) => {
    const isCorrect = direction === correctAnswer;

    // Common operations for all scenarios
    updateCurrentIndex(index - 1);
    updateCurrentProgressBar();
    console.log(progressBar)
    setOpenDialog(true);
    setDialogInfo({dialogImageLink, isCorrect, sourceLink, correctAnswer});

    // Check if correctAnswer is 'both'. If it is, avoid updating the result.
    if (correctAnswer !== 'both') {
        updateResult(isCorrect); // Updates the result only if correctAnswer is not 'both'
    }

    // Mixpanel tracking remains the same, logging all attempts
    mixpanel.track("Answer", {
        "answer": direction,
        "isCorrect": isCorrect, // This will record whether the answer was correct.
        "correctAnswer": correctAnswer,
    });
};



  const getTitleImage = (correctAnswer) => {
    if (correctAnswer === 'both') {
      return <img src={both} alt="both" style={{width: '70%'}} />;
    } else {
      return dialogInfo.isCorrect 
        ? <img src={youre_right} alt="You're right" style={{width: '70%'}} />
        : <img src={youre_wrong} alt="You're wrong" style={{width: '70%'}} />;
    }
  };


  const replay = () => {
    // Assuming reserveDatabases is never empty
    let newDataset = reserveDatabases[0];  
    // Update the reserveDatabases and preparedDatabase state
    setReserveDatabases(prev => prev.slice(1));
    setPreparedDatabase(newDataset);
    // No need to call updateCurrentIndex here; we'll handle it in the effect below
    mixpanel.track("Continue");
    updateProgressBarDenominator(newDataset.length)
  }
  
  // This effect runs whenever preparedDatabase changes
  useEffect(() => {
    if (preparedDatabase) {
      updateCurrentIndex(preparedDatabase.length - 1);
    }
  }, [preparedDatabase]);

  const swipe = async (dir) => {
    console.log(currentIndex)
    if (canSwipe && currentIndex < preparedDatabase.length) {
      await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
    }
  }
  return (
    <div>
      <link
        href='https://fonts.googleapis.com/css?family=Damion&display=swap'
        rel='stylesheet'
      />
      <link
        href='https://fonts.googleapis.com/css?family=Alatsi&display=swap'
        rel='stylesheet'
      />
      <link 
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
      integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossOrigin="anonymous"/>
      
      <img className='title' src={isis_or_hamas_white} alt=''/>
      <div className='cardContainer'>
        {
        preparedDatabase.map((data, index) => (
          <TinderCard
            ref={childRefs[index]}
            className='swipe'
            key={data.question_number}
            swipeThreshold={0.38}
            onSwipe={(dir) => swiped(dir, data.answer_link, index, data.left_or_right, data.source)}
            preventSwipe={['up', 'down']} 
          >
            <div
              style={{ backgroundImage: 'url(' + data.question_link + ')' }}
              className='card'
            >
            <p>{progressBar.currentIndex} / {progressBar.denominator}</p>
            </div>
          </TinderCard>
        ))}
      <div className='behind-cards'>
      
      <img src={precentege_title} alt="" style={{width: '80%'}} /> 
      <div className='precentage' >  {Math.round(result.score / result.questions * 100)}%</div> 
      <img src={punchline} alt="" style={{width: '80%'}} /> 
          {reserveDatabases.length > 0 ? (
            <button className='replay-button' style={{background_color: 'transparent'}} alt='Try Again'
            onClick={() => replay()}>
                <img src={replay_button} alt="Swipe left" 
                style={{
                    width: '100%',  // This sets the image width to 100% of the button width
                    height: 'auto'  // This maintains the aspect ratio of the image
                    }}
                />
            </button>
          ) : 
          <p className="further-reading">For further reading see <a href="https://www.washingtonpost.com/opinions/2023/10/17/hamas-isis-alqaeda-israel-gaza/"           target="_blank" 
          rel="noopener noreferrer" className="further-reading">The Washington Post's opinion article.</a></p>
          }
      </div>
      </div>
      <div className='buttons'>
      <button className="swipeButton" onClick={() => swipe('left')}>
        <img src={isis_flag} alt="Swipe left" style={{ width: '100%', height: 'auto' }} />
      </button>

      <button className="swipeButton" onClick={() => swipe('right')}>
       <img src={hamas_flag} alt="Swipe right" style={{ width: '100%', height: 'auto' }} />
      </button>
      </div>
<ShareButtons successPercentage={progressBar.currentIndex >= 6 ? Math.round(result.score / result.questions * 100): NaN} progressBar={progressBar}/>
<div>
</div>


  <OpenDialog.Provider value={{ openDialog, setOpenDialog }}>
    <DialogContent.Provider 
      value={{ 
        title: getTitleImage(dialogInfo.correctAnswer), 
        image_url: dialogInfo.dialogImageLink,
        source_url: dialogInfo.sourceLink
    }}>
      <SimpleDialogDemo/>
    </DialogContent.Provider>
  </OpenDialog.Provider>
</div>
  )
}

export default Advanced
